<template>
  <v-container>
    <customer-info logo="true" day-date="true" />

    <v-row>
      <v-col md="12">
        <h1>Training</h1>
      </v-col>
    </v-row>

    <h4>Coming Soon</h4>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "ForemanTraining",
  components: { CustomerInfo },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
